import React from "react";
import { graphql, PageProps } from "gatsby";

import Layout from "@components/Layout";
import Seo from "@components/Seo";
import PostList from "@components/Post/PostList";
import PageView from "@components/View/PageView";
import Wrapper from "@components/Wrapper";
import { PostFrontmatterType } from "@components/Post/type";

import * as styles from "./Category.module.scss";

type CategoryPageType = PageProps<
  {
    allMarkdownRemark: { nodes: PostFrontmatterType[]; totalCount: number };
  },
  { category: string }
>;

const Category = ({
  data: {
    allMarkdownRemark: { nodes },
  },
  pageContext: { category },
}: CategoryPageType) => {
  return (
    <Layout>
      <Wrapper>
        <PageView>
          <div className={styles.summary}>
            <h1 className={styles.title}>{category}</h1>
          </div>
          <PostList nodes={nodes} />
        </PageView>
      </Wrapper>
    </Layout>
  );
};

export default Category;

export const pageQuery = graphql`
  query ($category: String) {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { category: { eq: $category } } }
    ) {
      totalCount
      nodes {
        excerpt(pruneLength: 500)
        frontmatter {
          title
          subtitle
          date(formatString: "YYYY.MM.DD")
          category
          tags
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 1500)
            }
          }
        }
        fields {
          slug
        }
      }
    }
  }
`;
